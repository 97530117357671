






import { Component, Vue } from "vue-property-decorator";
import AdminConfiguratorArticleCompatibilityTable from "@/components/admin/configurator/article/compatibility/AdminConfiguratorArticleCompatibilityTable.vue";

/**
 * AdminConfiguratorArticleCompatibilityManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminConfiguratorArticleCompatibilityTable,
    },
})
export default class AdminConfiguratorArticleCompatibilityManagement extends Vue {}
