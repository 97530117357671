





































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { Article } from "@/types/shop/article";
import { ConfiguratorArticleCompatibility } from "@/types/configurator/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminConfiguratorArticleCompatibilityTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminConfiguratorArticleCompatibilityTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private articles: Article[] = [];
    private articleCompatibilities: ConfiguratorArticleCompatibility[] = [];

    private defaultArticleCompatibilityItem: ConfiguratorArticleCompatibility =
        {
            id: "",
            articleA: {
                id: -1,
                number: "",
                sellCount: 0,
                createDate: new Date().toLocaleString("de"),
                groups: [],
                producer: {
                    id: -1,
                    name: "",
                    homepage: "",
                    description: "",
                    image: {
                        id: -1,
                        src: "",
                        alt: "",
                    },
                },
                descriptions: [],
                images: [],
                isStock: false,
                stock: 0,
                deliveryTime: 0,
                reviews: [],
                prices: [],
                properties: [],
                deals: [],
            },
            articleB: {
                id: -1,
                number: "",
                sellCount: 0,
                createDate: new Date().toLocaleString("de"),
                groups: [],
                producer: {
                    id: -1,
                    name: "",
                    homepage: "",
                    description: "",
                    image: {
                        id: -1,
                        src: "",
                        alt: "",
                    },
                },
                descriptions: [],
                images: [],
                isStock: false,
                stock: 0,
                deliveryTime: 0,
                reviews: [],
                prices: [],
                properties: [],
                deals: [],
            },
        };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("shop.article").toString() + " A",
                value: "articleA.number",
            },
            {
                text: this.$tc("shop.article").toString() + " B",
                value: "articleB.number",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.articleCompatibilities, this.articles] = await Promise.all([
                this.fetchArticleCompatibilities(),
                this.fetchArticles(),
            ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all article compatibilities
     *
     * @returns Promise<ConfiguratorArticleCompatibility[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleCompatibilities(): Promise<
        ConfiguratorArticleCompatibility[]
    > {
        const response = (
            await axios.get<APIResponse<ConfiguratorArticleCompatibility[]>>(
                "/configurator/article/compatibilities"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all articles
     *
     * @returns Promise<Article[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticles(): Promise<Article[]> {
        const response = (await axios.get<APIResponse<Article[]>>("/articles"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
